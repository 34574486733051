<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">庆贺！福建历思司法鉴定所党支部与厦门市税务局第二稽查局检查三科党支部签订党建共建协议！</div>
      <p class="time">2019.12.08</p>
      <p class="space_content" >
        贯彻党的十九大精神，加强基层党支部建设，建立税企合作新机制，促进税企融合发展，2019年11月28日，福建历思司法鉴定所党支部（以下简称“历思”）与厦门市税务局第二稽查局检查三科党支部举行党建共建协议签约仪式，双方共同签署了《税企支部共建协议书 》。
      </p>
      <p class="space_content" >
        厦门市税务局第二稽查局党总支副书记、纪检组长苏法吾，厦门市税务局第二稽查局检查三科党支部书记、科长陈加兴，厦门市税务局第二稽查局检查三科副科长陈志杰等领导和历思相关负责人及全体党员出席本次签约仪式。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/27-1.png')">
      </div>

      <p class="space_content" >
        双方协议开展税企党组织共建活动，通过党建助力业务工作，共同构筑和谐的征纳关系，并达成如下共建内容：
      </p>
      <p class="space_content" >
        一、共同构筑共建机制，双方成立共建工作联系小组，加强日常沟通和信息交流，提高共建水平。
      </p>
      <p class="space_content" >
        二、共同开展组织生活，双方结合各自的工作特点，开展特色的党建交流等活动，丰富组织生活形式。
      </p>
      <p class="space_content" >
        三、共同组织党课学习，双方共同健全学习制度，共享党建资源，充分运用厦门党建e家、党建微信群等平台，及时推送党建知识，增强基层党组织的凝聚力和战斗力。
      </p>
      <p class="space_content" >
        四、共同促进队伍建设，双方探讨交流党员教育管理工作，培养入党积极分子，加大党员发展力度，扎实推进党员人才培养工作。
      </p>
      <p class="space_content" >
        五、共同开展群团活动，双方充分发挥党建带群团建设作用，以党团青年为主力军，开展志愿服务、慰问帮扶等活动，践行为人民服务的宗旨，积极回馈社会。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/27-2.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/27-3.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/27-4.png')">
      </div>

      <p class="space_content" >
        协议签订后，参加签约仪式的双方党员面向党旗，共同重温入党誓词，并以“党建共建聚合力，携手共进促发展”为主题进行座谈交流。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/27-5.png')">
      </div>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/26'"><p class="ellipsis1">武汉市司法局关太兵局长一行莅临福建历思司法鉴定所调研！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/28'"><p class="ellipsis1">庆贺！厦门CA与历思科技正式建立战略合作伙伴关系！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
